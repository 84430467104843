import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const StyledMarkdown = styled(Markdown)`
  display: block;
  flex-direction: column;

  > * + * {
    margin-block-start: ${spacing.small};
  }

  margin-bottom: ${spacing.medium};
`;
