import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { routes } from '@hultafors/hultaforsgroup/helpers';
import {
  BrandFragment,
  BrandsBlockFragment,
} from '@hultafors/hultaforsgroup/types';

import styles from './brands-block.module.scss';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Link = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.SharedLink),
);

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

type BrandsBlockProps = Omit<BrandsBlockFragment, 'id'>;

export const BrandsBlock: React.FC<BrandsBlockProps> = ({
  title,
  brands = [],
  introText,
}) => {
  if (!title && !brands.length && !introText) {
    return null;
  }
  const sizes = [
    `(min-width: ${breakpoints.desktop}) 148px`,
    `(min-width: ${breakpoints.tablet}) 25vw`,
    '50vw',
  ].join(', ');
  const brandMapper = ({ brandName, slug, imageLoad }: BrandFragment) => (
    <Link
      key={`key-${brandName}`}
      className={styles['link']}
      href={{ pathname: `${routes.brands}/${slug}` }}
      isWrapper
    >
      <DatoImage
        image={imageLoad}
        sizes={sizes}
        aspectRatio={1}
        alt={imageLoad?.alt || brandName || ''}
      />
      <Paragraph adjustStyles={{ marginBlock: 'var(--spacing-small) 0' }}>
        {brandName}
      </Paragraph>
    </Link>
  );

  return (
    <section
      className={clsx('content-container', 'wide', styles['wrapper'])}
      id="our-brands"
      data-test="OurBrandsBlock"
    >
      <HTag type="h2" className={styles['title']}>
        {title}
      </HTag>
      {introText && (
        <HTag type="p" styleType="p2-paragraph" className={styles['intro']}>
          {introText}
        </HTag>
      )}
      <div className={styles['grid']}>{brands.map(brandMapper)}</div>
    </section>
  );
};
