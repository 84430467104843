import React from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';

import type { SharedButtonProps } from '@hultafors/shared/components';

import styles from './button.module.scss';

const SharedButton = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Button),
);

type CtaButtonProps = SharedButtonProps;

// TODO - Implement this component in all blocks, and remove the Button component (?)
const Button: React.FC<CtaButtonProps> = ({
  className,
  label,
  loading,
  loadingLabel,
  onClick,
  textColor,
  to,
  center,
}) => {
  return (
    <SharedButton
      className={clsx(className, styles['cta-button'])}
      label={label}
      to={to}
      loading={loading}
      onClick={onClick}
      loadingLabel={loadingLabel}
      textColor={textColor}
      center={center}
      rounded
      uppercase
    />
  );
};

export default Button;
