import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerWideStyle } from '../content-container/content-container.styled';

export const ArticlesBlockStyled = styled.div`
  ${contentContainerWideStyle}
  padding: ${spacing.large} 0 ${spacing.XL};
  border-top: 1px solid ${colors.HGGray1};
`;

export const List = styled.div``;
