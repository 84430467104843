import { useState } from 'react';

import dynamic from 'next/dynamic';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { Button } from '../button-old/button';
import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface SubscribeAreaProps {
  subscribeInfoText?: string;
  subscribeButtonText?: string;
  userEmailFormUrl?: string;
  userEmailFormImage: any;
}

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl = '',
  userEmailFormImage = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <>
      <Paragraph adjustStyles={{ marginBlock: `0 ${spacing.regular}` }}>
        {subscribeInfoText}
      </Paragraph>
      <Button onClick={toggleDialog}>{subscribeButtonText}</Button>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
        />
      )}
    </>
  );
};
