import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const StoreItemStyled = styled.div`
  .Contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    &.VisitSite {
      justify-content: flex-start;
      padding: ${spacing.small} 0;
    }
  }
`;
