import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { getImageLoadStyle } from '@hultafors/shared/helpers/get-image-load-style/get-image-load-style';
import { getLuminance } from '@hultafors/shared/helpers/get-luminance';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  HeroBlockFragment,
  ImageLoadFragment,
} from '@hultafors/hultaforsgroup/types';

import Button from '../button/button';
import { Button as ButtonOld } from '../button-old/button';

import styles from './hero-block.module.scss';
import MuxPlayerContent from './mux-player-wrapper';

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Paragraph),
);

interface HeroBlockProps extends Omit<HeroBlockFragment, 'id'> {
  index?: number;
  priority?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  title,
  description,
  ctaText,
  ctaUrl,
  boxColor,
  priority,
  image,
  fullWidth,
  ctaButtons,
  textColor,
  video,
}) => {
  const { globalContent: global } = useGlobal();

  const style: Record<string, string> = {
    ...getImageLoadStyle<ImageLoadFragment, Record<string, string>>(image),
  };

  const boxColorHex = boxColor?.hex || '';
  const isBoxColorDark = !boxColorHex ? false : getLuminance(boxColorHex) < 128;
  if (boxColorHex) {
    style['--box-color'] = boxColorHex;
  }

  const whiteText = !!image || !!isBoxColorDark;
  const textColorHex = textColor?.hex || whiteText ? '#fff' : '#000';

  return (
    <section
      className={clsx(
        styles['hero'],
        boxColorHex && styles['has-color'],
        fullWidth && styles['hero--full-width'],
      )}
      style={style}
      data-test="hero-block"
    >
      <div
        className={clsx(
          styles['content'],
          fullWidth && styles['content--full-width'],
        )}
      >
        {video ? (
          <div className={styles['video-wrapper']}>
            <MuxPlayerContent video={video} />
          </div>
        ) : (
          <ResponsiveImage
            className={clsx(
              styles['image-wrapper'],
              fullWidth && styles['image-wrapper--full-width'],
            )}
            image={image}
            alt={title || image?.title}
            isBackground
            priority
          />
        )}
        <div className={clsx('content-container', styles['inner'])}>
          {title && (
            <HTag
              type={priority ? 'h1' : 'h2'}
              className={styles['title']}
              adjustStyles={{ color: textColorHex }}
              styleType={
                priority && !boxColorHex ? 'h1' : boxColorHex ? 'h4' : 'h2'
              }
            >
              {title}
            </HTag>
          )}

          {description && (
            <Paragraph
              className={styles['description']}
              adjustStyles={{
                color: textColorHex,
                marginBlock: 'var(--spacing-medium) var(--spacing-xregular)',
              }}
              styleType="p2-paragraph"
            >
              {description}
            </Paragraph>
          )}
          {ctaUrl && ctaText && (
            <ButtonOld
              to={ctaUrl}
              variant={boxColor?.hex ? 'white' : undefined}
            >
              {ctaText}
            </ButtonOld>
          )}
          {ctaButtons && (
            <div className={styles['button-container']}>
              {ctaButtons.map((ctaButton) => (
                <Button
                  key={ctaButton.id}
                  label={ctaButton.text}
                  to={ctaButton.url}
                  loadingLabel={global.loading}
                  textColor={whiteText ? '#FFF' : textColor?.hex}
                  className={styles['button']}
                  {...ctaButton}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
