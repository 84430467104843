import dynamic from 'next/dynamic';
import Image from 'next/image';

import { spacing } from '@hultafors/hultaforsgroup/helpers';
import { FullscreenImageCtaFragment } from '@hultafors/hultaforsgroup/types';

import { Button } from '../button-old/button';
import { ContentContainer } from '../content-container/content-container';

import {
  Box,
  ImageDesktop,
  ImageMobile,
  ImageTablet,
  ImageWrapper,
  StyledSquareTextCTABlock,
} from './square-text-cta-block.styled';

const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.HTag),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface SquareTextCTABlockProps {
  header?: string;
  text?: string;
  ctaText?: string;
  ctaUrl?: string;
  imageMobile?: FullscreenImageCtaFragment['imageMobile'];
  imageTablet?: FullscreenImageCtaFragment['imageTablet'];
  image?: FullscreenImageCtaFragment['image'];
}

export const SquareTextCTABlock: React.FC<SquareTextCTABlockProps> = ({
  header,
  text,
  imageMobile,
  imageTablet,
  image,
  ctaText,
  ctaUrl,
}) => {
  return (
    <StyledSquareTextCTABlock data-test="SquareTextCTABlock">
      <ImageWrapper>
        {imageMobile?.responsiveImage?.src && (
          <ImageMobile>
            <Image
              src={imageMobile.responsiveImage.src}
              alt={imageMobile.alt || header || ''}
              blurDataURL={imageMobile.responsiveImage?.base64 || ''}
              placeholder={
                imageMobile.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes="100vw"
              fill
            />
          </ImageMobile>
        )}
        {imageTablet?.responsiveImage?.src && (
          <ImageTablet>
            <Image
              src={imageTablet.responsiveImage.src}
              alt={imageTablet.alt || header || ''}
              blurDataURL={imageTablet.responsiveImage?.base64 || ''}
              placeholder={
                imageTablet.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes="100vw"
              fill
            />
          </ImageTablet>
        )}
        {image?.responsiveImage?.src && (
          <ImageDesktop>
            <Image
              src={image.responsiveImage.src}
              alt={image.alt || header || ''}
              blurDataURL={image.responsiveImage?.base64 || ''}
              placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              sizes="100vw"
              fill
            />
          </ImageDesktop>
        )}
      </ImageWrapper>
      <ContentContainer>
        <Box>
          <HTag type="h2" adjustStyles={{ color: '#fff' }}>
            {header}
          </HTag>
          <Paragraph
            adjustStyles={{
              color: '#fff',
              marginBlock: `0 ${spacing.xregular}`,
              textAlign: 'center',
            }}
          >
            {text}
          </Paragraph>
          <Button to={ctaUrl} variant="white">
            {ctaText}
          </Button>
        </Box>
      </ContentContainer>
    </StyledSquareTextCTABlock>
  );
};
