import { Fragment } from 'react';

import dynamic from 'next/dynamic';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import ExternalSvg from '../../svg/external.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import TagSvg from '../../svg/tag.svg';

import { StoreItemStyled } from './store-item.styled';

const Link = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.SharedLink),
);

const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

interface StoreItemProps {
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  mapLink?: string;
  view_on_map?: string | null | undefined;
  email?: string;
  phone?: string;
  websiteUrl?: string;
  visit_web_site?: string | null | undefined;
  local_web?: string;
  openingHours?: string;
}

export const StoreItem: React.FC<StoreItemProps> = (props) => {
  const {
    address,
    zipCode,
    city,
    country,
    mapLink,
    view_on_map,
    email,
    phone,
    websiteUrl,
    visit_web_site,
  } = props;

  const addressParts = [
    address,
    [zipCode, city].filter(Boolean).join(' '),
    country,
  ].filter(Boolean);
  const rowMapper = (row: string, index: number, all: string[]) => {
    return (
      <Fragment key={`row-${index}-${row}`}>
        {row}
        {index < all.length - 1 && <br />}
      </Fragment>
    );
  };
  return (
    <StoreItemStyled>
      <Paragraph adjustStyles={{ marginBlock: `0 ${spacing.regular}` }}>
        {addressParts.map(rowMapper)}
      </Paragraph>

      {view_on_map && mapLink && (
        <Link href={mapLink}>
          <TagSvg width={16} height={16} aria-hidden={true} focusable={false} />
          {view_on_map}
        </Link>
      )}

      {(email || phone) && (
        <>
          {email && (
            <div className="Email">
              <Link href={`mailto:${email}`}>
                <MailSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {email}
              </Link>
            </div>
          )}
          {phone && (
            <div className="Phone">
              <Link href={`tel:${phone}`}>
                <PhoneSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {phone}
              </Link>
            </div>
          )}
        </>
      )}

      {websiteUrl && (
        <div className="Contact VisitSite">
          <Link href={websiteUrl}>
            <ExternalSvg
              width={16}
              height={16}
              aria-hidden={true}
              focusable={false}
            />
            {visit_web_site}
          </Link>
        </div>
      )}
    </StoreItemStyled>
  );
};
